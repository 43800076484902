@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

body {
    /*font-family: 'Arial Rounded MT Regular', sans-serif;*/
    background-color: #f1f5f9; /* Set your desired background color */
}

.bg-aGgreen-lighter {
    background-color: hsl(185, 55%, 85%);
}

@font-face {
    font-family: 'Arial Rounded MT Bold';
    src: url('../src/assets/ARLRDBD.ttf') format('truetype');
}

@font-face {
    font-family: 'Arial Rounded MT Regular';
    src: url('../src/assets/Arial Rounded MT Regular.ttf') format('truetype');
}

.ArialRoundedBold {
    font-family: 'Arial Rounded MT Bold', Arial, sans-serif;
    /* Altri stili qui */
}

.ArialRoundedRegular {
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
    /* Altri stili qui */
}

.AppMainLabel {
    @apply
    text-aGred font-bold text-xs tracking-wider
}

.GarageMainLabel {
    @apply
    text-white font-bold text-xs tracking-wider
}


.icon-size {
    font-size: 18px;
}


.input-field {
    @apply
    bg-aGgreen-lighter font-semibold border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
}


.glass_selected {
    backdrop-filter: blur(8px); /* Adjust the blur amount as desired */
    background-color: rgba(255, 255, 255, 0.2); /* Adjust the opacity (4th value) to control the glass effect */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(226, 232, 240, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Adjust the z-index as needed */

}

.modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    width: 80%; /* Adjust as needed */
    max-width: 900px; /* Adjust as needed */
    padding: 20px;
    background-color: #f1f5f9;
    border-radius: 8px;
    box-shadow: 2px 2px 16px rgba(0.5, 0.5, 0.5, 0.5);
    z-index: 0;
}

.medium-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(226, 232, 240, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Adjust the z-index as needed */
}

.medium-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    width: 80%; /* Adjust as needed */
    max-width: 1300px; /* Adjust as needed */
    padding: 20px;
    background-color: #f1f5f9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 0;
}

.modal-inside-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    /*width: 50vw;*/
    /*height: 50vh;*/
    background-color: rgba(226, 232, 240, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Adjust the z-index as needed */
}

.modal-inside-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust as needed */
    max-width: 900px; /* Adjust as needed */
    padding: 20px;
    background-color: #f1f5f9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.9);
    z-index: 0;
}


.big-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(226, 232, 240, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Adjust the z-index as needed */
}

.big-modal-content {
    position: fixed;
    top: 52%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the modal precisely */
    width: 95%;
    height: 95%;
    background-color: #f1f5f9;
    z-index: 1001; /* Ensure the content is above the overlay */
    /*overflow: auto; !* Add scrollbars if content overflows *!*/
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Optional box shadow */
}


.actions-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 99.9%;
    height: 99.9%;
    background-color: rgba(226, 232, 240, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Adjust the z-index as needed */
}

.actions-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto; /* Let the content determine the width */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.filters-modal-content {
    position: fixed;
    top: 16%;
    left: 41%;
    transform: translate(-50%, -50%);
    /*width: auto; !* Let the content determine the width *!*/
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Styles for icons in a column (vertical) for screens with max width 768px */
@media (max-width: 768px) {
    .actions-modal-content {
        flex-direction: column;
        align-items: flex-start;
    }
}

.action-icon-large {
    font-size: 32px; /* Adjust the font-size as needed */
}


.recipe-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(226, 232, 240, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Adjust the z-index as needed */
}

.recipe-modal-content {
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -75%);
    width: 80%; /* Adjust as needed */
    max-width: 900px; /* Adjust as needed */
    padding: 20px;
    background-color: #f3f4f6;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.9);
    z-index: 0;
}

.dragging {
    border: 2px dashed #02959c; /* Green dashed border */
    background-color: transparent; /* Light green background color */
    opacity: 0.7; /* Slightly transparent */
    transition: all 0.3s ease-in-out; /* Smooth transition */
    border-radius: 8px; /* Rounded corners */
    padding-bottom: 15px;
    padding-top: 15px;
}


.form_title {
    @apply
    font-semibold text-gray-700 text-lg uppercase flex items-center justify-center;
}

/* styles.css */


