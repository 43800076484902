.AgMainDiv {
    @apply bg-gray-300 text-aGpetrol p-1 pl-2 rounded-lg m-2 shadow-md hover:bg-aGazure flex items-center justify-between tracking-wider ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
}

.AgMainDiv h1 {
    @apply mr-4;
    /* Add some right margin to create space between h1 and button */
}

.AgMainDiv button {
    @apply transition-transform transform origin-center;
}

.AgMainDiv button.rotate {
    transform: rotate(180deg);
}


.AgSubDiv {
    @apply bg-gray-200 text-gray-600 p-1 pl-2 rounded-lg shadow-md m-2 hover:bg-aGazure flex items-center justify-between tracking-wider ml-0 ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
}

.AgSubDiv h1 {
    @apply mr-4;
    /* Add some right margin to create space between h1 and button */
}

.AgSubDiv button {
    @apply ml-auto transition-transform transform origin-center;
}

.AgSubDiv button.rotate {
    transform: rotate(180deg);
}


.AgSubDiv2 {
    @apply bg-gray-50 text-aGazure p-1 pl-2 rounded-lg shadow-md m-2 hover:bg-gray-200 flex items-center justify-between tracking-wider ml-24 ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
}

.AgSubDiv2 h1 {
    @apply mr-4;
    /* Add some right margin to create space between h1 and button */
}

.AgSubDiv2 button {
    @apply ml-auto transition-transform transform origin-center;
}

.AgSubDiv2 button.rotate {
    transform: rotate(180deg);
}


.AgOrderScheduleDiv {
    @apply bg-gray-200 text-gray-500  p-1 pl-2 rounded-lg m-0.5 shadow-md hover:bg-aGazure flex items-center justify-between tracking-wider ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
    min-width: 200px;
    max-width: 200px;
}

.AgOrderScheduleDiv h1 {
    @apply mr-4;
    /* Add some right margin to create space between h1 and button */
}

.AgOrderScheduleDiv button {
    @apply transition-transform transform origin-center;
}

.AgOrderScheduleDiv button.rotate {
    transform: rotate(180deg);
}


@media (max-width: 1000px) {
    .AgOrderScheduleDiv {
        min-width: 150px;
        max-width: 150px;
    }

    .AgOrderScheduleDiv h1 {
        font-size: 11px;
    }
}

@media (max-width: 480px) {
    .AgOrderScheduleDiv {
        min-width: 100px;
        max-width: 100px;

    }

    .AgOrderScheduleDiv h1 {
        font-size: 10px;
    }
}


.filter_motion_div-divider-bar {
    height: 3px; /* Adjust the height as needed */
    background-color: #bad478; /* Choose your desired color */
    margin-top: 10px; /* Add margin to separate it from the content */
    margin-bottom: 20px; /* Add margin to separate it from the content */
}

.doc_form-divider-bar {
    height: 3px; /* Adjust the height as needed */
    background-color: #60b8c3; /* Choose your desired color */
    margin-top: 10px; /* Add margin to separate it from the content */
    margin-bottom: 20px; /* Add margin to separate it from the content */
}

.agDiv_transparent_div {
    @apply
    bg-transparent text-gray-700 uppercase text-[14px]  m-0.5 flex items-center justify-between tracking-wider
}

.agDiv_recipetransparent_div {
    @apply
    bg-gray-50 text-aGgreen text-xs p-1 pl-2 rounded-lg m-0.5 flex items-center justify-between tracking-wider
}

.agDiv_totals_div {
    @apply
    bg-gray-200 text-aGgreen text-xs p-1 pl-2 rounded-lg m-0.5 shadow-md flex items-center justify-between tracking-wider
}

.parent_div {
    position: relative; /* Set the parent div to relative position */
}

.note_container_div {
    position: relative;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    max-width: 550px;
    @apply
    bg-gray-200 text-gray-400
    p-2
    rounded-lg;
    white-space: pre-line;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.2);
}

.input-select-container {
    width: 100%;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    outline: none;
    transition: border-color 0.2s;
    flex: 1;
}