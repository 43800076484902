.percentage-chart-container {
    position: relative; /* Make the container relative */
    width: 300px; /* Set a specific width for the container or adjust as needed */
    height: 300px; /* Set a specific height for the container or adjust as needed */
    margin-top: 10px;
}

.percentage-label {
    position: absolute;
    top: 49%; /* Center the label vertically */
    left: 51%; /* Center the label horizontally */
    transform: translate(-50%, -50%); /* Center the label precisely */
    font-size: 1.5vw; /* Adjust the font size as needed */
    color: #60b8c3;
}

.percentage-title {
    position: absolute;
    top: 0%; /* Center the label vertically */
    left: 51%; /* Center the label horizontally */
    transform: translate(-50%, -50%); /* Center the label precisely */
    font-size: 0.7vw; /* Adjust the font size as needed */
    color: #60b8c3;
    text-transform: capitalize;
}


.chart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.percentages-label-wrapper {
    position: absolute;
    top: 50%; /* Center the label vertically */
    left: 150%; /* Center the label horizontally */
    transform: translate(-50%, -50%); /* Center the label precisely */
    font-size: 0.9vw; /* Adjust the font size as needed */
    color: #60b8c3;
}

.label-item {
    display: flex; /* Display labels in a flex container */
    padding: 1px;
    justify-content: center; /* Center labels horizontally */
    align-items: center; /* Center labels vertically */
    font-size: 0.9vw; /* Adjust the font size as needed */
    color: #60b8c3;
    margin-left: 50px;
}

.label-name {
    margin-right: 5px;

    font-weight: normal; /* Set the font weight to bold for a bold text */
}

.label-value {
    margin-right: 5px;
    font-weight: bold; /* Set the font weight to bold for a bold text */
}