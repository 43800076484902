
.AgLotDiv h1 {
    @apply mr-4;
    /* Add some right margin to create space between h1 and button */
}

.AgLotDiv button {
    @apply transition-transform transform origin-center;
}

.AgLotDiv button.rotate {
    transform: rotate(180deg);
}


@media (max-width: 1000px) {
    .AgLotDiv {
        min-width: 150px;
        max-width: 150px;
    }

    .AgLotDiv h1 {
        font-size: 11px;
    }
}

@media (max-width: 480px) {
    .AgLotDiv {
        min-width: 100px;
        max-width: 100px;

    }

    .AgLotDiv h1 {
        font-size: 10px;
    }
}

.icon-container-right {
    display: flex; /* Use flexbox to position icons horizontally */
    justify-content: flex-end; /* Align icons to the right */
    align-items: center; /* Center icons vertically */
}

.icon-item {
    margin-left: 4px; /* Add some spacing between icons */
}

.AgLotDiv {
    @apply text-gray-500  p-1 pl-2 rounded m-0.5 shadow-md flex items-center justify-between tracking-wider ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
    min-width: 200px;
    max-width: 200px;
}

.AgDivHeader {
    @apply bg-aGgreen text-white font-bold uppercase  p-1 pl-2 rounded m-0.5 shadow-md  flex items-center justify-between tracking-wider ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
    min-width: 200px;
    max-width: 200px;
}

.AgDivFooter {
    @apply bg-gray-500 text-white font-bold uppercase  p-1 pl-2 rounded m-0.5 shadow-md  flex items-center justify-between tracking-wider ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
    min-width: 200px;
    max-width: 200px;
}

.AgDivTransparent {
    @apply bg-transparent text-transparent font-bold uppercase  p-1 pl-2 rounded m-0.5  flex items-center justify-between tracking-wider ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
    min-width: 200px;
    max-width: 200px;
}

.AgLotDiv h1 {
    @apply mr-4;
    /* Add some right margin to create space between h1 and button */
}

.AgLotDiv button {
    @apply transition-transform transform origin-center;
}

.AgLotDiv button.rotate {
    transform: rotate(180deg);
}


@media (max-width: 1000px) {
    .AgLotDiv {
        min-width: 150px;
        max-width: 150px;
    }

    .AgLotDiv h1 {
        font-size: 11px;
    }
}

@media (max-width: 480px) {
    .AgLotDiv {
        min-width: 100px;
        max-width: 100px;

    }

    .AgLotDiv h1 {
        font-size: 10px;
    }
}


.number {
    font-family: Courier, monospace;
}

.negative {
    font-family: Courier, monospace;
    color: red;
}


.AgDivTanneryDyeingRecipe {
    /*@apply p-1 pl-2 rounded-lg m-0.5 shadow-md  flex items-center justify-between tracking-wider ;*/
    @apply bg-white text-gray-500  p-1 pl-2 rounded-lg m-0.5 shadow-md flex items-center justify-between tracking-wider ;
    font-family: 'Arial Rounded MT Regular', Arial, sans-serif;
}