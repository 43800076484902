/* Example CSS for toast messages with Tailwind CSS */
.toast {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 400px;
    padding: 10px 16px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
    backdrop-filter: blur(4px); /* Add blur effect */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    text-align: center; /* Center text when it spans multiple lines */
    z-index: 1002;
}

.toast-success {
    background-color: #60b8c3;
    border: 2px solid #02959c; /* Darker green border */
}

.toast-warning {
    background-color: rgba(240, 173, 78, 0.8); /* Light orange */
    border: 2px solid #ec971f; /* Darker orange border */
}

.toast-error {
    background-color: rgba(217, 83, 79, 0.8); /* Light red */
    border: 2px solid #c9302c; /* Darker red border */
}
